const messages = {
    nav: {
        Participants: '參與者',
        income: '參與者收入',
        Wallet: '連接錢包',
        yes: '登录成功',
        no: '未签名,请刷新'
    },
    link: {
        partner: '合作夥伴',
        Codes: '未發現矩陣，無邀請碼',
        joinMe: '與我一起加入',
        andEarn: '並賺取',
        copyLink: '複製我的鏈接',
        downloadImage: '複製我的邀請碼',
        postDescription: '用於您的帖子的描述',
        futureHasArrived: '的未來已經到來！',
        earnIncome: '在中賺取了可觀的收入。加入我的團隊，一起合作並探索這個令人興奮的新世界。',
        heyExplore: '嘿！我正在探索元宇宙時賺錢。加入我一起踏上',
        discussDetails: '之旅！與我討論詳細信息。',
        heyEarnRealMoney: '嘿！我在探索元宇宙時賺取真正的錢。加入我的團隊，與',
        notJustAGame: '一起發現互聯網的未來。這不僅僅是一款遊戲；這是一個全新的現實！隨時與我聯繫以獲取更多詳細信息。'
    },
    sigin: {
        cannot: '地址不能爲空',
        Welcome: '歡迎來到',
        flagship: '是去中心化系統的旗艦產品，提供廣泛的產品數量',
        Registera: '使用推薦鏈接註冊，然後你有更好的機會您邀請的人將註冊嚴格按照您的鏈接。',
        By: '通過參與該計劃，您獲得利潤並維持我們系統的平衡。',
        upline: '你的上線',
        code: '輸入您的邀請碼',
        Please: '請在此填入您的邀請碼',
        Invitation: '邀請碼',
        Confirm: '確認',
        Registerb: '註冊',
        that: '確認您同意使用條款並按下註冊按鈕',
        agree: '我同意',
        Use: '使用條款',
        not: '我不是美國或阿聯酋公民。',
        compatible: '如何在',
        United: '中註冊',
        Registerd: '在您的智慧手機或',
        How: '上安裝',
        wallet: '兼容的錢包。',
        mmmmm: '錢包',
        Wallet: '向您的錢包填充至少',
        additional: '使用加密貨幣交換或附加服務（在應用程序',
        aa: '）購買加密貨幣。',
        cryptocurrency: '對於',
        For: '擊註冊按鈕繼續註冊。 智慧手機 - 打開加密錢包中的瀏覽器選項卡。',
        Click: '請輸入',
        invitation: '邀請碼不存在',
        successful: '註冊成功',
        Please2: '請確認',
    },
    setting: {
        Edit: '編輯',
        Basic: '基本信息',
        Select: '選擇照片',
        About: '關於我',
        Cancel: '取消',
        Confirm: '確定'
    },
    mypage: {
        Followers: '關注者',
        Following: '正在關注',
        About: '關於我',
        Log: '登出',
        Learn: '瞭解更多',
    },
    matrix: {
        tssss: '正在分析支付錯誤原因，大約10秒左右，請勿刷新',
        free: '免費',
        freea: '余額不足',
        freeayew: '鏈接錢包中',
        xxxxx: '網絡異常',
        have: '如果您已經激活了槽位級別但更改未立即顯示 - 可能會有約30秒的延遲。',
        Participant: '參與者',
        Enable: '啟用',
        loangin: '正在支付中，請勿離開頁面',
        moxxxx: '支付錯誤',
    },
    level: {
        Return: '返回',
        Recently: '最近關於',
        Activity: '的活動',
        All: '所有圈子',
        hours: '小時前',
        participant: '新參與者',
    },
    homepagea: {
        Upline: '我的上線',
        Wallet: '錢包地址',
        Level: '觸感等級',
        Total: '結構中的合作夥伴總數',
        Personal: '個人邀請',
        Your: '您的合作夥伴已賺取',
        Recent: '最近的活動 矩陣系統',
        New: '新參與者',
        Show: '顯示更多',
    },
    homein: {
        Home: '首頁',
        System: '系統',
        Team: '我的團隊',
        Settings: '設置',
        Notifications: '通知',
        Following: '正在關注',
        Mark: '標記為已讀',
        Read: '閱讀更多',
        Disconnect: '斷開連接',
        Wallet: '錢包',
        Total: '餘額共計',
        Balance: '錢包餘額',
        Copy: '復制我的鏈接',
        My: '我的個人資料',
        Bar: '導航欄',
        Rights: '保留所有權利',
        Terms: '網站使用條款',
        stt: 'ST星球',
        rsl: '燃燒樂',
        CommunityPage: '社區'
    },
    homepage: {
        title: '歡迎來到',
        wirie: '對於初學者，請閱讀',
        Whitepaper: '白皮書',
        quickly: '這將幫助您快速熟悉所有機制',
        understand: '並了解您的目標產品',
        Download: '下載',
        Read: '現在閱讀',
        flagship: '是去中心化系統的旗艦產品，提供',
        wide: '廣泛的產品和活動，為世界帶來',
        combination: '金融和虛擬現實結合在一起',
        vision: '我們的願景是為您快速積累您的財富',
        wealth: '安全、快速、財富',
        virtual: '在每個人都可以開始生活的虛擬宇宙',
        Neat: '乾淨利落，賺大錢',
        News: '新聞',
        View: '查看所有文章',
        Submit: '提交問題到郵箱'
    },
    FireDance: {
        nextLotteryAnnouncement: '次日12：00開獎，中獎平分獎池,每天11:50到12:00禁止購買',
        pendingDraw: '待開獎',
        selectNumber: '選號碼',
        myLuckyNumber: '我的幸運號碼',
        currentPrizePool: '當前獎池',
        yesterdayLuckyNumber: '昨日幸運號碼',
        yesterdayPrizePool: '昨日獎池',
        numberOfWinners: '中獎注數',
        enterLuckyNumber: '請輸入您的幸運號碼',
        betMultiplier: '下注倍數',
        myRandomNumber: '我的隨機號碼',
        randomLuckyNumber: '隨機幸運號',
        settle: '結算',
        purchase: '購買',
        chooseLuckyNumber: '選擇幸運號',
        back: '返回',
        notOpen: '未开放',
        
        purchaseRecord: "購買記錄",
        number: "號碼",
        betCount: "注數",
        status: "狀態",
        inProgress: "競猜中",
        notWon: "未中獎",
        won: "中獎",
        confirmPurchase: "請確認購買",
        currentNumber: "當前號碼",
        cancel: "取消",
        confirm: "確認",
        insufficientBalance: "余額不足",
        betCountAbnormal: "注數異常",
        purchaseSuccessful: "購買成功"
    },
    ChargePage: {
        daaa: '激活',
        dasa: '充能中',
        daaf: '充能完成',
        dafda: '點擊充能',
        davxa: '可獲取',
        da33a: '收集成功',
        da33ss: '星球等級',
        da33aa: '星球算力',
        da33dddd: '運輸難度',
        da33deefgg: '查看更多',
        da33sasd22g: '當前産出',
        errta: '充能記錄',
        errtas: '時間',
    },
    CommunityPage:{
        aaaa:'我的社區',
        bbbb:'社區項目',
        cccc:'項目名稱',
        dsa:'最少投資',
        dsww:'預計總投資',
        ass:'投資佔比',
        wea:'股份',
        deeee:'私募時間',
        ssdw:'開發週期',
        tyyyy:'月',
        tyydsfy:'公測時間',
        tyffryyy:'上線時間',
        tyrssy:'入股人數限制',
        tyygrrryy:'人',
        tyrrryy:'投資回報率',
        tyyyyyy:'聯繫方式',
        tyiiiyy:'項目類型',
        iityy:'選擇團隊',
        itiiiyyy:'成員',
        tykkyy:'上傳圖片介紹',
        tyyyyk:'上傳文件包',
        tyyyyks:'項目介紹信息',
        tyyyyka:'提交項目',
        tyyyykf:'項目審覈費用',
        tyyyysssskf:'選擇日期時間',
    },
    AllCommunity:{
        sttt:'您有什麼好項目需要發佈',
        attt:'發佈項目',
        svttt:'社區項目',
        svgttt:'顯示項目',
        sthhtt:'查看詳情',
        strtt:'發佈',
        stttt:'編輯',
        sttyt:'啓動資金',
        stutt:'審覈中',
        sttit:'',
        stptt:'',
        sllttt:'',
        stoott:'',
        siuuuttt:'',
        skllttt:'',
        stliitt:'',
        sttddzt:'',
    },
    UserCommunity:{
        aaaaa: '未找到社群',
        bbbbb: '成员',
        ccccc: '進入',
        ddddd: '加入',
        eeeee: '請確認創建',
        fffff: '取消',
        ggggg: '確認',
        hhhhh: '請確認刪除',
        iiiii: '群名',
        jjjjj: '人數',
        kkkkk: '群頭像',
        lllll: '群簡介',
        mmmmm: '創建',
        nnnnn: '創建費用',
        ooooo: '有什麼新鮮事',
        ppppp: '發帖',
        qqqqq: '輸入你想要搜索的社群信息',
        rrrrr: '創建社群',
        sssss: '社群',
        ttttt: '我的社群',
        uuuuu: '我的帖子',
        vvvvv: '發布時間',
        wwwww: '前',
        xxxxx: '查看詳情',
        yyyyy: '已經到底了',
        zzzzz: '刪除',
        aaaaaa: '點擊加載更多',
        bbbbbb: '刪除成功',
        cccccc: '刪除失敗',
        dddddd: '請先完善個人信息',
        eeeeee: '請輸入',
        ffffff: '社區名字長度異常',
        gggggg: '社區簡介長度異常',
        hhhhhh: '請上傳頭像',
        iiiiii: '餘額不足',
        jjjjjj: '正在提交中',
        kkkkkk: '圖片上傳異常',
        llllll: '社區創建成功',
        mmmmmm: '最多只能上傳9張圖片',
        nnnnnn: '請輸入文字',
        oooooo: '回覆成功',
        pppppp: '回覆失敗',
        ssswww: '返回',
        ssswwwa: '回复',
    },
    CommunityDetails:{
        asssss1:'發帖',
        asssss2:'請確認',
        asssss3:'解散',
        asssss4:'離開',
        asssss5:'歡迎來到',
        asssss6:'正在提交中',
        asssss7:'請輸入文字',
        asssss8:'圖片上傳異常',
        asssss9:'發布成功',
        asssss10:'發布失敗',
        asssss11:'回复成功',
        asssss12:'回复失敗',
        }
}

export default messages
