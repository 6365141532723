const messages = {
  nav: {
    Participants: 'Участники',
    income: 'Доход участников',
    Wallet: 'Подключить кошелек',
    yes: 'Вход выполнен успешно',
    no: 'Не подписано, пожалуйста, обновите'
  },
  link: {
    partner: 'Партнеры',
    Codes: 'Матрица не найдена, нет кода приглашения',
    joinMe: 'Присоединяйтесь ко мне',
    andEarn: 'и зарабатывайте',
    copyLink: 'Скопировать мою ссылку',
    downloadImage: 'Скопировать мой пригласительный код',
    postDescription: 'Описание для вашего поста',
    futureHasArrived: 'Будущее наступило!',
    earnIncome: 'Заработайте значительный доход в',
    joinMyTeam: 'Присоединяйтесь к моей команде и вместе исследуйте этот захватывающий новый мир.',
    heyExplore: 'Эй! Я зарабатываю деньги, исследуя метавселенную. Присоединяйтесь ко мне в этом путешествии!',
    discussDetails: 'Обсудим детали.',
    heyEarnRealMoney: 'Эй! Я зарабатываю настоящие деньги, исследуя метавселенную. Присоединяйтесь к моей команде и откройте для себя будущее интернета с',
    notJustAGame: 'Это не просто игра; это совершенно новая реальность! Свяжитесь со мной в любое время для получения дополнительной информации.'
  },
  sigin: {
    cannot: 'Адрес не может быть пустым',
    Welcome: 'Добро пожаловать в',
    flagship: 'Это флагманский продукт децентрализованной системы, предлагающий широкий ассортимент продукции',
    Registera: 'Зарегистрируйтесь, используя реферальную ссылку, и у вас будет больше шансов, что приглашенные вами люди зарегистрируются строго по вашей ссылке.',
    By: 'Участвуя в этой программе, вы получаете прибыль и поддерживаете баланс нашей системы.',
    upline: 'Ваш аплайн',
    code: 'Введите ваш пригласительный код',
    Please: 'Пожалуйста, введите здесь ваш пригласительный код',
    Invitation: 'Пригласительный код',
    Confirm: 'Подтвердить',
    Registerb: 'Зарегистрироваться',
    that: 'Подтвердите, что вы согласны с условиями использования и нажмите кнопку регистрации',
    agree: 'Я согласен',
    Use: 'Условия использования',
    not: 'Я не являюсь гражданином Соединенных Штатов или ОАЭ.',
    compatible: 'Как зарегистрироваться в',
    United: 'средней регистрации',
    Registerd: 'Установите совместимый кошелек на ваш смартфон или',
    How: 'верхняя установка',
    wallet: 'совместимый кошелек.',
    mmmmm: 'Кошелек',
    Wallet: 'Пополните ваш кошелек как минимум на',
    additional: 'Купите криптовалюту, используя биржу криптовалют или дополнительные услуги (в приложении',
    aa: ')купить криптовалюту',
    cryptocurrency: 'Для',
    For: 'Нажмите кнопку регистрации, чтобы продолжить регистрацию. Смартфон - откройте вкладку браузера в кошельке криптовалют.',
    Click: 'Пожалуйста, введите',
    invitation: 'Пригласительный код не существует',
    successful: 'Регистрация успешна',
    Please2: 'Пожалуйста, подтвердите',
  },
  setting: {
    Edit: 'Редактировать',
    Basic: 'Основная информация',
    Select: 'Выбрать фото',
    About: 'Обо мне',
    Cancel: 'Отмена',
    Confirm: 'Подтвердить',
  },
  mypage: {
    Followers: 'Подписчики',
    Following: 'Подписки',
    About: 'Обо мне',
    Log: 'Выйти',
    Learn: 'Узнать больше',
  },
  matrix: {
    tssss: 'Причина ошибки платежа анализируется, около 10 секунд, не обновляйте страницу',
    free: 'бесплатно',
    freea: 'Недостаточный баланс',
    xxxxx: 'Исключение сети',
    freeayew: 'Связывание кошелька',
    have: 'Если вы уже активировали уровень слота, но изменение не отображается сразу - может быть задержка около 30 секунд.',
    Participant: 'Участник',
    Enable: 'Включить',
    loangin: 'Пожалуйста, не покидайте страницу во время оплаты',
    moxxxx: 'Ошибка платежа',
  },
  level: {
    Return: 'Вернуться',
    Recently: 'Недавно о',
    Activity: 'активность',
    All: 'Все круги',
    hours: 'часов назад',
    participant: 'Новый участник',
  },
  homepagea: {
    Upline: 'Мой Аплайн',
    Wallet: 'Адрес Кошелька',
    Level: 'Уровень Доступа',
    Total: 'Общее Количество Партнеров в Структуре',
    Personal: 'Личные Приглашения',
    Your: 'Ваши Партнеры Заработали',
    Recent: 'Последние Действия в Системе Матрицы',
    New: 'Новый Участник',
    Show: 'Показать Больше',
  },
  homein: {
    Home: 'Домой',
    System: 'Система',
    Team: 'Моя Команда',
    Settings: 'Настройки',
    Notifications: 'Уведомления',
    Following: 'Подписки',
    Mark: 'Отметить как Прочитанное',
    Read: 'Читать Далее',
    Disconnect: 'Отключить',
    Wallet: 'Кошелек',
    Total: 'Общий Баланс',
    Balance: 'Баланс Кошелька',
    Copy: 'Скопировать Мою Ссылку',
    My: 'Мой Профиль',
    Bar: 'Панель Навигации',
    Rights: 'Все Права Защищены',
    Terms: 'Условия Использования',
    stt: 'Планета ST',
    rsl: 'Горящее Веселье',
    CommunityPage: 'Сообщество'
  },
  homepage: {
    title: 'Добро пожаловать в',
    wirie: 'Для начинающих, читайте далее',
    Whitepaper: 'Белая книга',
    quickly: 'Это поможет вам быстро ознакомиться со всеми механизмами',
    understand: 'И понять вашу целевую продукцию',
    Download: 'Скачать',
    Read: 'Читать сейчас',
    flagship: 'Является флагманским продуктом децентрализованной системы, предлагая',
    wide: 'Широкий ассортимент продукции и мероприятий, представляя миру',
    combination: 'Сочетание финансов и виртуальной реальности',
    vision: 'Наша цель - помочь вам быстро накопить ваше богатство',
    wealth: 'Безопасно, быстро, богатство',
    virtual: 'В виртуальной вселенной, где каждый может начать жить',
    Neat: 'Аккуратно и ухоженно, зарабатывайте большие деньги',
    News: 'Новости',
    View: 'Просмотреть все статьи',
    Submit: 'Отправить вопрос по электронной почте'
  },
  FireDance: {
    nextLotteryAnnouncement: 'Следующий розыгрыш лотереи в 12:00 следующего дня, победители делят призовой фонд, запрещено покупать с 11:50 до 12:00 каждый день',
    pendingDraw: 'Ожидается розыгрыш',
    selectNumber: 'Выбрать номер',
    myLuckyNumber: 'Мой счастливый номер',
    currentPrizePool: 'Текущий призовой фонд',
    yesterdayLuckyNumber: 'Счастливый номер вчерашнего дня',
    yesterdayPrizePool: 'Призовой фонд вчерашнего дня',
    numberOfWinners: 'Количество победителей',
    enterLuckyNumber: 'Введите ваш счастливый номер',
    betMultiplier: 'Множитель ставки',
    myRandomNumber: 'Мой случайный номер',
    randomLuckyNumber: 'Случайный счастливый номер',
    settle: 'Расчет',
    purchase: 'Покупка',
    chooseLuckyNumber: 'Выбрать счастливый номер',
    back: 'Назад',
    notOpen: 'Не открыто',
    purchaseRecord: "Запись Покупки",
    number: "Номер",
    betCount: "Количество Ставок",
    status: "Статус",
    inProgress: "В Процессе",
    notWon: "Не Выиграл",
    won: "Выиграл",
    confirmPurchase: "Подтвердите Покупку",
    currentNumber: "Текущий Номер",
    cancel: "Отмена",
    confirm: "Подтвердить",
    insufficientBalance: "Недостаточный Баланс",
    betCountAbnormal: "Ненормальное Количество Ставок",
    purchaseSuccessful: "Покупка Успешна"
  },
  ChargePage: {
    daaa: 'Активировать',
    dasa: 'Зарядка',
    daaf: 'Зарядка завершена',
    dafda: 'Нажмите для зарядки',
    davxa: 'Можно получить',
    da33a: 'Сбор успешен',
    da33ss: 'Уровень планеты',
    da33aa: 'Вычислительная мощность планеты',
    da33dddd: 'Сложность транспортировки',
    da33deefgg: 'Подробнее',
    da33sasd22g: 'Текущий Выход',
    errta: 'Запись Зарядки',
    errtas: 'Время',
  },
  CommunityPage: {
    aaaa: 'Мое сообщество',
    bbbb: 'Проекты сообщества',
    cccc: 'Название проекта',
    dsa: 'Минимальные инвестиции',
    dsww: 'Ожидаемые общие инвестиции',
    ass: 'Доля инвестиций',
    wea: 'Акции',
    deeee: 'Время частного размещения',
    ssdw: 'Цикл разработки',
    tyyyy: 'Месяцы',
    tyydsfy: 'Время бета-тестирования',
    tyffryyy: 'Время запуска',
    tyrssy: 'Ограничение количества инвесторов',
    tyygrrryy: 'Люди',
    tyrrryy: 'Доходность инвестиций',
    tyyyyyy: 'Контактная информация',
    tyiiiyy: 'Тип проекта',
    iityy: 'Выбор команды',
    itiiiyyy: 'Члены',
    tykkyy: 'Загрузка изображения введения',
    tyyyyk: 'Загрузка пакета файлов',
    tyyyyks: 'Информация о введении проекта',
    tyyyyka: 'Подача проекта',
    tyyyykf: 'Стоимость рассмотрения проекта',
    tyyyysssskf: 'Выберите дату и время',
  },
  UserCommunity: {
    aaaaa: 'Сообщество не найдено',
    bbbbb: 'Члены',
    ccccc: 'Войти',
    ddddd: 'Присоединиться',
    eeeee: 'Подтвердите создание',
    fffff: 'Отмена',
    ggggg: 'Подтверждение',
    hhhhh: 'Подтвердите удаление',
    iiiii: 'Название группы',
    jjjjj: 'Количество людей',
    kkkkk: 'Аватар группы',
    lllll: 'Описание группы',
    mmmmm: 'Создать',
    nnnnn: 'Плата за создание',
    ooooo: 'Что нового',
    ppppp: 'Пост',
    qqqqq: 'Введите информацию о сообществе, которое вы хотите найти',
    rrrrr: 'Создать сообщество',
    sssss: 'Сообщество',
    ttttt: 'Мои сообщества',
    uuuuu: 'Мои посты',
    vvvvv: 'Время публикации',
    wwwww: 'перед',
    xxxxx: 'Подробнее',
    yyyyy: 'Уже внизу',
    zzzzz: 'Удалить',
    aaaaaa: 'Нажмите, чтобы загрузить больше',
    bbbbbb: 'Успешное удаление',
    cccccc: 'Не удалось удалить',
    dddddd: 'Пожалуйста, заполните личную информацию',
    eeeeee: 'Пожалуйста, введите',
    ffffff: 'Необычная длина имени сообщества',
    gggggg: 'Необычная длина описания сообщества',
    hhhhhh: 'Пожалуйста, загрузите аватар',
    iiiiii: 'Недостаточно средств',
    jjjjjj: 'Подается',
    kkkkkk: 'Ошибка загрузки изображения',
    llllll: 'Сообщество успешно создано',
    mmmmmm: 'Максимум можно загрузить только 9 изображений',
    nnnnnn: 'Пожалуйста, введите текст',
    oooooo: 'Успешный ответ',
    pppppp: 'Не удалось ответить',
    ssswww: 'Назад',
    ssswwwa: 'Ответить'
  },
  CommunityDetails:{
    asssss1:'Опубликовать',
    asssss2:'Пожалуйста, подтвердите',
    asssss3:'Расформировать',
    asssss4:'Покинуть',
    asssss5:'Добро пожаловать в',
    asssss6:'Отправка',
    asssss7:'Пожалуйста, введите текст',
    asssss8:'Ошибка загрузки изображения',
    asssss9:'Публикация успешна',
    asssss10:'Публикация не удалась',
    asssss11:'Ответ успешен',
    asssss12:'Ответ не удался',
    }
}

export default messages
