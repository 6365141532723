import { createRouter, createWebHashHistory } from 'vue-router'


const routes = [
  {
    path: '/HomePagenull',
    name: 'Homenull',
    component: () => import('@/views/home/HomeNull.vue')
  },
  {
    path: '/SignIn',
    name: 'SignIn',
    component: () => import('@/views/home/SignIn.vue')
  },
  {
    path: '/HomeIn',
    name: 'HomeIn',
    component: () => import('@/views/home/HomeIn.vue'),
    children: [
      {
        path: "/HomeIn/HomePage",
        component: () => import("@/views/home/HomePage.vue"),
        name: 'HomePage'
      },
      {
        path: "/HomeIn/MatrixMatrix",
        component: () => import("@/views/home/MatrixMatrix.vue"),
        name: 'MatrixMatrix'
      },
      {
        path: "/HomeIn/MyTeam",
        component: () => import("@/views/home/MyTeam.vue"),
        name: 'MyTeam'
      }, {
        path: "/HomeIn/SettingsPage",
        component: () => import("@/views/home/SettingsPage.vue"),
        name: 'SettingsPage'
      }, {
        path: "/HomeIn/MyPage",
        component: () => import("@/views/home/MyPage.vue"),
        name: 'MyPage'
      },

      {
        path: "/HomeIn/MatrixLevel",
        component: () => import("@/views/home/MatrixLevel.vue"),
        name: 'MatrixLevel'
      },
      {
        path: "/HomeIn/MatrixSystem",
        component: () => import("@/views/home/MatrixSystem.vue"),
        name: 'MatrixSystem'
      },
      {
        path: "/HomeIn/ChargePage",
        component: () => import("@/views/home/ChargePage.vue"),
        name: 'ChargePage'
      },
      {
        path: "/HomeIn/FireDance",
        component: () => import("@/views/home/FireDance.vue"),
        name: 'FireDance'
      },
      {
        path: "/HomeIn/CommunityPage",
        component: () => import("@/views/home/CommunityPage.vue"),
        name: 'CommunityPage',
      },
      {
        path: "/HomeIn/AllCommunityPage",
        component: () => import("@/views/home/AllCommunityPage.vue"),
        name: 'AllCommunityPage'
      },
      {
        path: "/HomeIn/PublishProject",
        component: () => import("@/views/home/PublishProject.vue"),
        name: 'PublishProject'
      },
      {
        path: "/HomeIn/DetailsPage",
        component: () => import("@/views/home/DetailsPage.vue"),
        name: 'DetailsPage'
      },
      {
        path: "/HomeIn/CommunityDetails",
        component: () => import("@/views/home/CommunityDetails.vue"),
        name: 'CommunityDetails'
      },
      {
        path: "/HomeIn",
        redirect: "/HomeIn/MatrixMatrix"
      },
    ]
  },
  {
    path: "/",
    redirect: "/HomePagenull"
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
