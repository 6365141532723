const messages = {
    nav: {
        Participants: 'ผู้เข้าร่วม',
        income: 'รายได้ของผู้เข้าร่วม',
        Wallet: 'เชื่อมต่อกระเป๋าเงิน',
        yes: 'เข้าสู่ระบบสำเร็จ',
        no: 'ไม่ได้เข้าสู่ระบบ กรุณารีเฟรช'
    },
    link: {
        partner: 'พาร์ทเนอร์',
        Codes: 'ประธานาธิบดีประธานาธิบดี',
        joinMe: 'ร่วมกับฉัน',
        andEarn: 'และได้รับ',
        copyLink: 'คัดลอกลิงค์ของฉัน',
        downloadImage: 'คัดลอกรหัสเชิญของฉัน',
        postDescription: 'คำอธิบายสำหรับโพสต์ของคุณ',
        futureHasArrived: 'อนาคตได้มาถึงแล้ว!',
        earnIncome: 'ได้รับรายได้มากมาย ร่วมทีมกับฉัน',
        heyExplore: 'เฮ้! ฉันกำลังหาเงินขณะที่สำรวจเมตาเวิร์ส ร่วมเดินทางนี้กับฉัน!',
        discussDetails: 'มาพูดคุยรายละเอียดกับฉัน',
        heyEarnRealMoney: 'เฮ้! ฉันกำลังหาเงินจริงขณะที่สำรวจเมตาเวิร์ส ร่วมทีมกับฉันและค้นพบอนาคตของอินเทอร์เน็ต',
        notJustAGame: 'มันไม่ใช่แค่เกม; มันคือความเป็นจริงใหม่! ติดต่อฉันได้ทุกเมื่อเพื่อข้อมูลเพิ่มเติม'
    },
    sigin: {
        cannot:'ที่อยู่ไม่สามารถว่า งได้',
        Welcome: 'ยินดีต้อนรับสู่',
        flagship: 'เป็นผลิตภัณฑ์หลักของระบบกระจายอำนาจ โดยมีหลากหลายปริมาณผลิตภัณฑ์',
        Registera: 'ลงทะเบียนโดยใช้ลิงค์อ้างอิง แล้วคุณจะมีโอกาสที่ดีกว่าที่คนที่คุณเชิญจะลงทะเบียนตามลิงค์ของคุณอย่างเคร่งครัด',
        By: 'โดยการเข้าร่วมโปรแกรมนี้ คุณจะได้รับผลกำไรและรักษาความสมดุลของระบบของเรา',
        upline: 'อัพไลน์ของคุณ',
        code: 'ใส่รหัสเชิญของคุณ',
        Please: 'โปรดใส่รหัสเชิญของคุณที่นี่',
        Invitation: 'รหัสเชิญ',
        Confirm: 'ยืนยัน',
        Registerb: 'ลงทะเบียน',
        that: 'ยืนยันว่าคุณเห็นด้วยกับข้อกำหนดในการใช้งานและกดปุ่มลงทะเบียน',
        agree: 'ฉันเห็นด้วย',
        Use: 'ข้อกำหนดในการใช้งาน',
        not: 'ฉันไม่ใช่ประชาชนของสหรัฐอเมริกาหรือ UAE',
        compatible: 'วิธีการลงทะเบียนใน',
        United: 'การลงทะเบียนระดับปานกลาง',
        Registerd: 'ติดตั้งกระเป๋าสตางค์ที่เข้ากันได้ในสมาร์ทโฟนหรือ',
        How: 'ติดตั้งด้านบน',
        wallet: 'กระเป๋าสตางค์ที่เข้ากันได้',
        mmmmm: 'กระเป๋าสตางค์',
        Wallet: 'เติมเงินในกระเป๋าสตางค์ของคุณอย่างน้อย',
        additional: 'ซื้อสกุลเงินดิจิทัลโดยใช้แลกเปลี่ยนสกุลเงินดิจิทัลหรือบริการเสริม (ในแอป',
        aa: ') ซื้อสกุลเงินดิจิทัล',
        cryptocurrency: 'สำหรับ',
        For: 'คลิกปุ่มลงทะเบียนเพื่อดำเนินการลงทะเบียนต่อไป สมาร์ทโฟน - เปิดแท็บเบราว์เซอร์ในกระเป๋าสตางค์ดิจิทัล',
        Click: 'กรุณาใส่',
        invitation: 'รหัสเชิญไม่มีอยู่',
        successful: 'ลงทะเบียนส',
        Please2:'กรุณายืนยัน',
    },
    setting: {
        Edit: 'แก้ไข',
        Basic: 'ข้อมูลพื้นฐาน',
        Select: 'เลือกรูปภาพ',
        About: 'เกี่ยวกับฉัน',
        Cancel: 'ยกเลิก',
        Confirm: 'ยืนยัน',
    },
    mypage: {
        Followers: 'ผู้ติดตาม',
        Following: 'กำลังติดตาม',
        About: 'เกี่ยวกับฉัน',
        Log: 'ออกจากระบบ',
        Learn: 'เรียนรู้เพิ่มเติม',
    },
    matrix: {
        tssss:'กำลังวิเคราะห์ข้อผิดพลาดในการชำระเงินประมาณ 10 วินาที หรืออย่ารีเฟรช',
        free:'ฟรี',
        freea:'ไม่สมดุล',
        xxxxx:'ระบบเครือข่ายผิดปกติ',
        freeayew:'กำลังเชื่อมต่อกระเป๋าเงิน',
        have: 'หากคุณได้เปิดใช้งานระดับสล็อตแล้วแต่การเปลี่ยนแปลงไม่แสดงทันที - อาจมีความล่าช้าประมาณ 30 วินาที',
        Participant: 'ผู้เข้าร่วม',
        Enable: 'เปิดใช้งาน',
        loangin:'กำลังชำระเงินอยู่อย่าออกจากหน้า',
        moxxxx:'การจ่ายเงินผิดพลาด',
    },
    level: {
        Return: 'กลับ',
        Recently: 'เร็วๆ นี้เกี่ยวกับ',
        Activity: 'กิจกรรมของ',
        All: 'วงกลมทั้งหมด',
        hours: 'ชั่วโมงที่ผ่านมา',
        participant: 'ผู้เข้าร่วมใหม่',
    },
    homepagea: {
        Upline: 'อัพไลน์ของฉัน',
        Wallet: 'ที่อยู่กระเป๋าเงิน',
        Level: 'ระดับทัช',
        Total: 'จำนวนพาร์ทเนอร์ทั้งหมดในโครงสร้าง',
        Personal: 'คำเชิญส่วนตัว',
        Your: 'พาร์ทเนอร์ของคุณได้รับ',
        Recent: 'กิจกรรมล่าสุดในระบบแมทริกซ์',
        New: 'ผู้เข้าร่วมใหม่',
        Show: 'แสดงเพิ่มเติม',
    },
    homein: {
        Home: 'หน้าแรก',
        System: 'ระบบ',
        Team: 'ทีมของฉัน',
        Settings: 'การตั้งค่า',
        Notifications: 'การแจ้งเตือน',
        Following: 'กำลังติดตาม',
        Mark: 'ทำเครื่องหมายว่าอ่านแล้ว',
        Read: 'อ่านเพิ่มเติม',
        Disconnect: 'ยกเลิกการเชื่อมต่อ',
        Wallet: 'กระเป๋าเงิน',
        Total: 'ยอดรวม',
        Balance: 'ยอดคงเหลือในกระเป๋าเงิน',
        Copy: 'คัดลอกลิงก์ของฉัน',
        My: 'โปรไฟล์ของฉัน',
        Bar: 'แถบนำทาง',
        Rights: 'สงวนลิขสิทธิ์ทั้งหมด',
        Terms: 'ข้อกำหนดในการใช้งาน',
        stt:'ดาวเคราะห์ ST',
        rsl:'ความสุขที่ลุกโชน',
        CommunityPage:'ชุมชน'
    },
    homepage: {
        title: 'ยินดีต้อนรับสู่',
        wirie: 'สำหรับผู้เริ่มต้น อ่านต่อ',
        Whitepaper: 'ไวท์เปเปอร์',
        quickly: 'สิ่งนี้จะช่วยให้คุณคุ้นเคยกับกลไกทั้งหมดอย่างรวดเร็ว',
        understand: 'และเข้าใจผลิตภัณฑ์เป้าหมายของคุณ',
        Download: 'ดาวน์โหลด',
        Read: 'อ่านตอนนี้',
        flagship: 'เป็นผลิตภัณฑ์หลักของระบบกระจายอำนาจ โดยมี',
        wide: 'ผลิตภัณฑ์และกิจกรรมที่หลากหลาย นำเสนอให้กับโลก',
        combination: 'การผสมผสานของการเงินและความเป็นจริงเสมือน',
        vision: 'วิสัยทัศน์ของเราคือช่วยให้คุณสะสมทรัพย์สินอย่างรวดเร็ว',
        wealth: 'ปลอดภัย เร็ว และร่ำรวย',
        virtual: 'ในจักรวาลเสมือนจริงที่ทุกคนสามารถเริ่มต้นชีวิตได้',
        Neat: 'เรียบร้อยและหาเงินได้มาก',
        News: 'ข่าว',
        View: 'ดูบทความทั้งหมด',
        Submit:'ส่งคำถามไปยังอีเมล'
    },
    FireDance: {
        nextLotteryAnnouncement: 'ประกาศล็อตเตอรี่ครั้งต่อไปเวลา 12:00 ของวันถัดไป, ผู้ชนะจะแบ่งปันรางวัล, ห้ามซื้อตั้งแต่ 11:50 ถึง 12:00 ทุกวัน',
        pendingDraw: 'รอการออกรางวัล',
        selectNumber: 'เลือกหมายเลข',
        myLuckyNumber: 'หมายเลขโชคดีของฉัน',
        currentPrizePool: 'รางวัลปัจจุบัน',
        yesterdayLuckyNumber: 'หมายเลขโชคดีเมื่อวาน',
        yesterdayPrizePool: 'รางวัลเมื่อวาน',
        numberOfWinners: 'จำนวนผู้ชนะ',
        enterLuckyNumber: 'กรอกหมายเลขโชคดีของคุณ',
        betMultiplier: 'ตัวคูณการเดิมพัน',
        myRandomNumber: 'หมายเลขสุ่มของฉัน',
        randomLuckyNumber: 'หมายเลขโชคดีแบบสุ่ม',
        settle: 'ตัดสิน',
        purchase: 'ซื้อ',
        chooseLuckyNumber: 'เลือกหมายเลขโชคดี',
        back: 'กลับ',
        notOpen: 'ยังไม่เปิด',
        purchaseRecord: "บันทึกการซื้อ",
        number: "หมายเลข",
        betCount: "จำนวนการเดิมพัน",
        status: "สถานะ",
        inProgress: "กำลังดำเนินการ",
        notWon: "ไม่ชนะ",
        won: "ชนะ",
        confirmPurchase: "กรุณายืนยันการซื้อ",
        currentNumber: "หมายเลขปัจจุบัน",
        cancel: "ยกเลิก",
        confirm: "ยืนยัน",
        insufficientBalance: "ยอดเงินไม่พอ",
        betCountAbnormal: "จำนวนการเดิมพันผิดปกติ",
        purchaseSuccessful: "ซื้อสำเร็จ"
    },
    ChargePage: {
        daaa: 'เปิดใช้งาน',
        dasa: 'กำลังชาร์จ',
        daaf: 'ชาร์จเสร็จสมบูรณ์',
        dafda: 'คลิกเพื่อชาร์จ',
        davxa: 'สามารถรับได้',
        da33a: 'การเก็บรวบรวมสำเร็จ',
        da33ss: 'ระดับดาวเคราะห์',
        da33aa: 'พลังงานคำนวณของดาวเคราะห์',
        da33dddd: 'ความยากในการขนส่ง',
        da33deefgg: 'ดูเพิ่มเติม',
        da33sasd22g:'ผลผลิตปัจจุบัน',
        errta:'บันทึกการชาร์จ',
        errtas:'เวลา',
    },
    CommunityPage: {
        aaaa: 'ชุมชนของฉัน',
        bbbb: 'โครงการของชุมชน',
        cccc: 'ชื่อโครงการ',
        dsa: 'การลงทุนขั้นต่ำ',
        dsww: 'การลงทุนทั้งหมดที่คาดหวัง',
        ass: 'สัดส่วนการลงทุน',
        wea: 'หุ้น',
        deeee: 'เวลาจัดสรรเอกชน',
        ssdw: 'รอบการพัฒนา',
        tyyyy: 'เดือน',
        tyydsfy: 'เวลาทดสอบเบต้า',
        tyffryyy: 'เวลาเปิดตัว',
        tyrssy: 'จำกัดจำนวนผู้ลงทุน',
        tyygrrryy: 'คน',
        tyrrryy: 'ผลตอบแทนจากการลงทุน',
        tyyyyyy: 'ข้อมูลติดต่อ',
        tyiiiyy: 'ประเภทโครงการ',
        iityy: 'เลือกทีม',
        itiiiyyy: 'สมาชิก',
        tykkyy: 'อัปโหลดภาพนำเสนอ',
        tyyyyk: 'อัปโหลดแพ็คเกจไฟล์',
        tyyyyks: 'ข้อมูลนำเสนอโครงการ',
        tyyyyka: 'ส่งโครงการ',
        tyyyykf: 'ค่าธรรมเนียมการตรวจสอบโครงการ',
        tyyyysssskf:'เลือกวันที่และเวลา',
    },
    UserCommunity:{
        aaaaa: 'ไม่พบชุมชน',
        bbbbb: 'สมาชิก',
        ccccc: 'เข้า',
        ddddd: 'เข้าร่วม',
        eeeee: 'ยืนยันการสร้าง',
        fffff: 'ยกเลิก',
        ggggg: 'ยืนยัน',
        hhhhh: 'ยืนยันการลบ',
        iiiii: 'ชื่อกลุ่ม',
        jjjjj: 'จำนวนคน',
        kkkkk: 'โลโก้กลุ่ม',
        lllll: 'คำอธิบายกลุ่ม',
        mmmmm: 'สร้าง',
        nnnnn: 'ค่าใช้จ่ายในการสร้าง',
        ooooo: 'มีข่าวอะไร',
        ppppp: 'โพสต์',
        qqqqq: 'ป้อนข้อมูลชุมชนที่คุณต้องการค้นหา',
        rrrrr: 'สร้างชุมชน',
        sssss: 'ชุมชน',
        ttttt: 'ชุมชนของฉัน',
        uuuuu: 'โพสต์ของฉัน',
        vvvvv: 'เวลาที่โพสต์',
        wwwww: 'ก่อน',
        xxxxx: 'ดูรายละเอียด',
        yyyyy: 'ได้ถึงสิ้นสุดแล้ว',
        zzzzz: 'ลบ',
        aaaaaa: 'คลิกเพื่อโหลดเพิ่มเติม',
        bbbbbb: 'ลบสำเร็จ',
        cccccc: 'ลบไม่สำเร็จ',
        dddddd: 'โปรดกรอกข้อมูลส่วนตัว',
        eeeeee: 'โปรดป้อน',
        ffffff: 'ชื่อชุมชนมีความยาวผิดปกติ',
        gggggg: 'คำอธิบายชุมชนมีความยาวผิดปกติ',
        hhhhhh: 'โปรดอัปโหลดรูปภาพโปรไฟล์',
        iiiiii: 'ยอดเงินไม่เพียงพอ',
        jjjjjj: 'กำลังส่งข้อมูล',
        kkkkkk: 'การอัปโหลดรูปภาพผิดพลาด',
        llllll: 'สร้างชุมชนเรียบร้อยแล้ว',
        mmmmmm: 'สามารถอัปโหลดรูปภาพได้มากสุด 9 ภาพเท่านั้น',
        nnnnnn: 'โปรดป้อนข้อความ',
        oooooo: 'ตอบกลับสำเร็จ',
        pppppp: 'ตอบกลับล้มเหลว',
        ssswww: 'กลับ',
        ssswwwa: 'ตอบกลับ',
    },
    CommunityDetails:{
        asssss1:'โพสต์',
        asssss2:'โปรดยืนยัน',
        asssss3:'ยุบ',
        asssss4:'ออกจาก',
        asssss5:'ยินดีต้อนรับสู่',
        asssss6:'กำลังส่ง',
        asssss7:'โปรดป้อนข้อความ',
        asssss8:'อัปโหลดรูปภาพผิดปกติ',
        asssss9:'เผยแพร่สำเร็จ',
        asssss10:'เผยแพร่ล้มเหลว',
        asssss11:'ตอบกลับสำเร็จ',
        asssss12:'ตอบกลับล้มเหลว',
        } 
                            
}
export default messages
