import { createStore } from 'vuex'

export default createStore({
  state: {
    USDT_ADDRESS: '0x55d398326f99059fF775485246999027B3197955',// 测试USDT代币合约地址
    stAddress: '0xf39876B2874De87229143cdD26E76Eb47121732f',// 测试ST代币合约地址
    Addre: '0x38',// 测试ST代币合约地址
    bnb: 0,
    st: 0,
    usdt: 0,
  },
  getters: {
  },
  mutations: {
    Message(state, { bnb, usdt }) {
      state.bnb = bnb
      state.usdt = usdt
    },
    Stage(state, { st }) {
      state.st = st
    },
  },
  actions: {
  },
  modules: {
  }
})
