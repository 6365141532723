<template>
  <div class="all">
    <router-view />
  </div>
</template>

<script setup>

import { useRouter } from 'vue-router';
const router = useRouter();
function mong() {
  if (window.ethereum) {
    window.ethereum.on('accountsChanged', function () {
      localStorage.removeItem('token');
      localStorage.removeItem('inviteCode');
      sessionStorage.clear();
      router.push({
        path: '/HomePagenull',
      });
    });
  }
}
mong()
</script>

<style lang="scss">
.all {
  min-height: 100vh;
  background-color: black;
  color: white;
}
</style>
