const messages = {
    nav: {
        Participants: '참가자들',
        income: '참가자 수입',
        Wallet: '지갑 연결',
        yes: '로그인 성공',
        no: '로그인되지 않았습니다. 새로 고침하세요'
    },
    link: {
        partner: '파트너',
        Codes: '행렬, 초대장 코드가 없습니다',
        joinMe: '나와 함께 가입하세요',
        andEarn: '그리고 벌다',
        copyLink: '내 링크 복사하기',
        downloadImage: '내 초대 코드를 복사합니다',
        postDescription: '게시물 설명',
        futureHasArrived: '미래가 왔습니다!',
        earnIncome: '상당한 수입을 얻었습니다. 내 팀에 가입하고 함께 이 흥미로운 새로운 세계를 탐험하세요.',
        heyExplore: '안녕! 메타버스를 탐험하며 돈을 벌고 있어요. 이 여정에 나와 함께하세요!',
        discussDetails: '나와 자세한 내용을 논의하세요.',
        heyEarnRealMoney: '안녕! 메타버스를 탐험하며 진짜 돈을 벌고 있어요. 내 팀에 가입하고 인터넷의 미래를 함께 발견하세요.',
        notJustAGame: '단순한 게임이 아닙니다; 완전히 새로운 현실이에요! 자세한 내용은 언제든지 저에게 연락하세요.'
    },
    sigin: {
        cannot:'주소가 비어 있을 수 없습니다',
        Welcome: '환영합니다',
        flagship: '탈중앙화 시스템의 주력 제품으로 다양한 제품 라인을 제공합니다',
        Registera: '추천 링크를 사용하여 등록하면, 초대한 사람들이 귀하의 링크에 따라 등록할 가능성이 더 높아집니다.',
        By: '이 프로그램에 참여함으로써 수익을 얻고 시스템의 균형을 유지합니다.',
        upline: '당신의 업라인',
        code: '초대 코드를 입력하세요',
        Please: '여기에 초대 코드를 입력해 주세요',
        Invitation: '초대 코드',
        Confirm: '확인',
        Registerb: '등록',
        that: '이용 약관에 동의하고 등록 버튼을 누르세요',
        agree: '동의합니다',
        Use: '이용 약관',
        not: '나는 미국 또는 UAE의 시민이 아닙니다.',
        compatible: '어떻게 등록하나요',
        United: '중간 등록',
        Registerd: '스마트폰이나',
        How: '상단 장착',
        wallet: '호환 가능한 지갑.',
        mmmmm: '지갑',
        Wallet: '최소한으로 지갑을 충전하세요',
        additional: '암호화폐 거래소나 추가 서비스를 사용하여 (앱에서',
        aa: ') 암호화폐를 구매하세요',
        cryptocurrency: '을 위해',
        For: '등록 버튼을 클릭하여 등록을 계속하세요. 스마트폰 - 암호화폐 지갑의 브라우저 탭을 열어주세요.',
        Click: '입력해 주세요',
        invitation: '초대 코드가 존재하지 않습니다',
        successful: '등록 성공',
        Please2: '확인해 주세요',
    },
    setting: {
        Edit: '편집',
        Basic: '기본 정보',
        Select: '사진 선택',
        About: '나에 대해서',
        Cancel: '취소',
        Confirm: '확인',
    },
    mypage: {
        Followers: '팔로워',
        Following: '팔로잉',
        About: '나에 대해',
        Log: '로그아웃',
        Learn: '더 알아보기',
    },
    matrix: {
        tssss:'지불 오류 원인을 분석 중이오니 10초 정도 갱신하지 마십시오',
        free:'무료로',
        freea:'잔액이 부족하다',
        xxxxx:'네트워크 이상',
        freeayew:'지갑 연결 중',
        have: '슬롯 레벨을 이미 활성화했지만 변경 사항이 즉시 표시되지 않는 경우 - 약 30초의 지연이 있을 수 있습니다.',
        Participant: '참가자',
        Enable: '활성화',
        loangin:'결제 중입니다. 페이지를 떠나지 마십시오',
        moxxxx:'결제 오류',
    },
    level: {
        Return: '돌아가기',
        Recently: '최근에 대해',
        Activity: '활동',
        All: '모든 원',
        hours: '시간 전',
        participant: '새로운 참가자',
    },
    homepagea: {
        Upline: '내 업라인',
        Wallet: '지갑 주소',
        Level: '터치 레벨',
        Total: '구조 내 파트너 총 수',
        Personal: '개인 초대',
        Your: '당신의 파트너가 벌어들인',
        Recent: '매트릭스 시스템의 최근 활동',
        New: '새로운 참가자',
        Show: '더 보기',
    },
    homein: {
        Home: '홈',
        System: '시스템',
        Team: '내 팀',
        Settings: '설정',
        Notifications: '알림',
        Following: '팔로잉',
        Mark: '읽음으로 표시',
        Read: '더 읽기',
        Disconnect: '연결 해제',
        Wallet: '지갑',
        Total: '총 잔액',
        Balance: '지갑 잔액',
        Copy: '내 링크 복사',
        My: '내 프로필',
        Bar: '네비게이션 바',
        Rights: '모든 권리 보유',
        Terms: '이용 약관',
        stt:'ST 행성',
        rsl:'타오르는 즐거움',
        CommunityPage:'커뮤니티'
    },
    homepage: {
        title: '환영합니다',
        wirie: '초보자를 위해 계속 읽어보세요',
        Whitepaper: '백서',
        quickly: '모든 메커니즘을 빠르게 익히는 데 도움이 됩니다',
        understand: '그리고 귀하의 타겟 제품을 이해하세요',
        Download: '다운로드',
        Read: '지금 읽기',
        flagship: '탈중앙화 시스템의 주력 제품으로 제공되는',
        wide: '다양한 제품 및 활동을 세계에 제공합니다',
        combination: '금융과 가상 현실의 조합',
        vision: '당신의 부를 빠르게 축적할 수 있도록 돕는 것이 우리의 비전입니다',
        wealth: '안전하고 빠르며 부유함',
        virtual: '모두가 생활을 시작할 수 있는 가상 우주에서',
        Neat: '깔끔하고 큰 돈을 벌다',
        News: '뉴스',
        View: '모든 기사 보기',
        Submit:'이메일로 문의 제출'
    },
    FireDance: {
        nextLotteryAnnouncement: '다음 추첨은 내일 12:00에 발표됩니다. 당첨자는 상금을 나눠 가집니다. 매일 11:50부터 12:00까지 구매 금지',
        pendingDraw: '추첨 대기 중',
        selectNumber: '번호 선택',
        myLuckyNumber: '내 행운의 번호',
        currentPrizePool: '현재 상금 풀',
        yesterdayLuckyNumber: '어제의 행운의 번호',
        yesterdayPrizePool: '어제의 상금 풀',
        numberOfWinners: '당첨자 수',
        enterLuckyNumber: '당신의 행운의 번호를 입력하세요',
        betMultiplier: '배팅 배수',
        myRandomNumber: '내 무작위 번호',
        randomLuckyNumber: '무작위 행운의 번호',
        settle: '정산',
        purchase: '구매',
        chooseLuckyNumber: '행운의 번호 선택',
        back: '뒤로',
        notOpen: '미개방',
        purchaseRecord: "구매 기록",
        number: "번호",
        betCount: "베팅 수",
        status: "상태",
        inProgress: "진행 중",
        notWon: "미당첨",
        won: "당첨",
        confirmPurchase: "구매 확인 요망",
        currentNumber: "현재 번호",
        cancel: "취소",
        confirm: "확인",
        insufficientBalance: "잔액 부족",
        betCountAbnormal: "베팅 수 이상",
        purchaseSuccessful: "구매 성공"
    },
    ChargePage: {
        daaa: '활성화',
        dasa: '충전 중',
        daaf: '충전 완료',
        dafda: '충전하려면 클릭',
        davxa: '획득 가능',
        da33a: '수집 성공',
        da33ss: '행성 레벨',
        da33aa: '행성 컴퓨팅 파워',
        da33dddd: '운송 난이도',
        da33deefgg: '더 보기',
        da33sasd22g:'현재 출력',
        errta:'충전 기록',
        errtas:'시간',
    },
    CommunityPage: {
        aaaa: '나의 커뮤니티',
        bbbb: '커뮤니티 프로젝트',
        cccc: '프로젝트 이름',
        dsa: '최소 투자',
        dsww: '예상 총 투자',
        ass: '투자 비율',
        wea: '주식',
        deeee: '사모 시간',
        ssdw: '개발 주기',
        tyyyy: '월',
        tyydsfy: '공개 테스트 시간',
        tyffryyy: '출시 시간',
        tyrssy: '투자자 수 제한',
        tyygrrryy: '사람',
        tyrrryy: '투자 수익률',
        tyyyyyy: '연락처 정보',
        tyiiiyy: '프로젝트 유형',
        iityy: '팀 선택',
        itiiiyyy: '멤버',
        tykkyy: '이미지 소개 업로드',
        tyyyyk: '파일 패키지 업로드',
        tyyyyks: '프로젝트 소개 정보',
        tyyyyka: '프로젝트 제출',
        tyyyykf: '프로젝트 심사 비용',
        tyyyysssskf:'날짜와 시간 선택',
    },
    UserCommunity:{
        aaaaa: '커뮤니티를 찾을 수 없습니다',
        bbbbb: '회원',
        ccccc: '들어가기',
        ddddd: '가입',
        eeeee: '만들기를 확인하십시오',
        fffff: '취소',
        ggggg: '확인',
        hhhhh: '삭제 확인',
        iiiii: '그룹 이름',
        jjjjj: '인원',
        kkkkk: '그룹 로고',
        lllll: '그룹 소개',
        mmmmm: '만들기',
        nnnnn: '만들기 비용',
        ooooo: '새로운 소식이 있나요',
        ppppp: '게시물 작성',
        qqqqq: '검색할 커뮤니티 정보를 입력하세요',
        rrrrr: '커뮤니티 만들기',
        sssss: '커뮤니티',
        ttttt: '내 커뮤니티',
        uuuuu: '내 게시물',
        vvvvv: '게시 시간',
        wwwww: '전',
        xxxxx: '자세히 보기',
        yyyyy: '이미 끝났어요',
        zzzzz: '삭제',
        aaaaaa: '더 많이 로드하려면 클릭하세요',
        bbbbbb: '삭제 성공',
        cccccc: '삭제 실패',
        dddddd: '먼저 개인 정보를 완료하십시오',
        eeeeee: '입력하십시오',
        ffffff: '커뮤니티 이름 길이 이상',
        gggggg: '커뮤니티 소개 길이 이상',
        hhhhhh: '프로필 사진을 업로드하십시오',
        iiiiii: '잔액 부족',
        jjjjjj: '제출 중입니다',
        kkkkkk: '이미지 업로드 오류',
        llllll: '커뮤니티가 성공적으로 생성되었습니다',
        mmmmmm: '최대 9 개의 이미지 만 업로드 할 수 있습니다',
        nnnnnn: '텍스트를 입력하십시오',
        oooooo: '답장 성공',
        pppppp: '답장 실패',
        ssswww: '되돌아 가기',
        ssswwwa: '답장',
    },
    CommunityDetails:{
        asssss1:'게시물 작성',
        asssss2:'확인 요청',
        asssss3:'해산',
        asssss4:'탈퇴',
        asssss5:'환영합니다',
        asssss6:'제출 중',
        asssss7:'텍스트를 입력하세요',
        asssss8:'이미지 업로드 오류',
        asssss9:'게시 성공',
        asssss10:'게시 실패',
        asssss11:'답글 작성 성공',
        asssss12:'답글 작성 실패',
        }                
}

export default messages
