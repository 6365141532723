const messages = {
    nav: {
        Participants: 'Participants',
        income: 'Participant income ',
        Wallet: 'Connect Wallet',
        yes: 'Login successful',
        no: 'Unsigned, please refresh'
    },
    link: {
        partner: 'Partners',
        Codes: 'No matrix found, no invitation code',
        joinMe: 'Join me',
        andEarn: 'and earn',
        copyLink: 'Copy my link',
        downloadImage: 'Copy my invitation code',
        postDescription: 'Description for your post',
        futureHasArrived: 'The future has arrived!',
        earnIncome: 'Earned substantial income in',
        joinMyTeam: 'Join my team and explore this exciting new world together.',
        heyExplore: 'Hey! I am making money while exploring the metaverse. Join me on this journey!',
        discussDetails: 'Discuss the details with me.',
        heyEarnRealMoney: 'Hey! I am earning real money while exploring the metaverse. Join my team and discover the future of the internet with',
        notJustAGame: 'This is not just a game; it is a whole new reality! Contact me anytime for more details.'
    },
    sigin: {
        cannot:'The address cannot be empty',
        Welcome: 'Welcome to',
        flagship: 'It is the flagship product of the decentralized system, offering a wide range of product quantities',
        Registera: 'Register using the referral link, and then you have a better chance that the people you invite will register strictly according to your link.',
        By: 'By participating in this program, you earn profits and maintain the balance of our system.',
        upline: 'Your upline',
        code: 'Enter your invitation code',
        Please: 'Please enter your invitation code here',
        Invitation: 'Invitation code',
        Confirm: 'Confirm',
        Registerb: 'Register',
        that: 'Confirm that you agree to the terms of use and press the register button',
        agree: 'I agree',
        Use: 'Terms of Use',
        not: 'I am not a citizen of the United States or the UAE.',
        compatible: 'How to register in',
        United: 'medium registration',
        Registerd: 'Install a compatible wallet on your smartphone or',
        How: 'top mount',
        wallet: 'compatible wallet.',
        mmmmm: 'Wallet',
        Wallet: 'Top up your wallet with at least',
        additional: 'Buy cryptocurrency using a cryptocurrency exchange or additional services (in the app',
        aa: ')buy cryptocurrency',
        cryptocurrency: 'For',
        For: 'Click the register button to continue registration. Smartphone - Open the browser tab in the cryptocurrency wallet.',
        Click: 'Please enter',
        invitation: 'The invitation code does not exist',
        successful: 'Registration successful',
        Please2: 'Please confirm',
    },
    setting: {
        Edit: 'Edit',
        Basic: 'Basic Information',
        Select: 'Select Photo',
        About: 'About Me',
        Cancel: 'Cancel',
        Confirm: 'Confirm',
    },
    mypage: {
        Followers: 'Followers',
        Following: 'Following',
        About: 'About Me',
        Log: 'Log Out',
        Learn: 'Learn More',
    },
    matrix: {
        tssss:'The cause of payment error is being analyzed, about 10 seconds, do not refresh',
        free:'free',
        freea:'Insufficient balance',
        xxxxx:'Network exception',
        freeayew:'Linking Wallet',
        have: 'If you have already activated the slot level but the change is not immediately displayed - there may be a delay of about 30 seconds.',
        Participant: 'Participant',
        Enable: 'Enable',
        loangin:'Please do not leave the page while paying',
        moxxxx:'Error of payment',
    },
    level: {
        Return: 'Return',
        Recently: 'Recently about',
        Activity: 'activity of',
        All: 'All circles',
        hours: 'hours ago',
        participant: 'New participant',
    },
    homepagea: {
        Upline: 'My Upline',
        Wallet: 'Wallet Address',
        Level: 'Touch Level',
        Total: 'Total Number of Partners in the Structure',
        Personal: 'Personal Invitations',
        Your: 'Your Partners Have Earned',
        Recent: 'Recent Activities in the Matrix System',
        New: 'New Participant',
        Show: 'Show More',
    },
    homein: {
        Home: 'Home',
        System: 'System',
        Team: 'My Team',
        Settings: 'Settings',
        Notifications: 'Notifications',
        Following: 'Following',
        Mark: 'Mark as Read',
        Read: 'Read More',
        Disconnect: 'Disconnect',
        Wallet: 'Wallet',
        Total: 'Total Balance',
        Balance: 'Wallet Balance',
        Copy: 'Copy My Link',
        My: 'My Profile',
        Bar: 'Navigation Bar',
        Rights: 'All Rights Reserved',
        Terms: 'Terms of Use',
        stt:'ST Planet',
        rsl:'Burning Joy',
        CommunityPage:'Community'
    },
    homepage: {
        title: 'Welcome to',
        wirie: 'For beginners, read on',
        Whitepaper: 'Whitepaper',
        quickly: 'This will help you quickly familiarize yourself with all the mechanisms',
        understand: 'And understand your target product',
        Download: 'Download',
        Read: 'Read now',
        flagship: 'Is the flagship product of the decentralized system, offering',
        wide: 'A wide range of products and activities, bringing to the world',
        combination: 'The combination of finance and virtual reality',
        vision: 'Our vision is to help you quickly accumulate your wealth',
        wealth: 'Safe, fast, wealth',
        virtual: 'In a virtual universe where everyone can start living',
        Neat: 'Neat and tidy, earn big money',
        News: 'News',
        View: 'View all articles',
        Submit:'Submit Question via Email'
    },
    FireDance: {
        nextLotteryAnnouncement: 'The next lottery draw is at 12:00 PM. Winners share the prize pool. Purchase is prohibited from 11:50 AM to 12:00 PM.',
        pendingDraw: 'Pending Draw',
        selectNumber: 'Select Number',
        myLuckyNumber: 'My Lucky Number',
        currentPrizePool: 'Current Prize Pool',
        yesterdayLuckyNumber: 'Yesterday’s Lucky Number',
        yesterdayPrizePool: 'Yesterday’s Prize Pool',
        numberOfWinners: 'Number of Winners',
        enterLuckyNumber: 'Enter Your Lucky Number',
        betMultiplier: 'Bet Multiplier',
        myRandomNumber: 'My Random Number',
        randomLuckyNumber: 'Random Lucky Number',
        settle: 'Settle',
        purchase: 'Purchase',
        chooseLuckyNumber: 'Choose  Number',
        back: 'Back',
        notOpen: 'Not Open',
        purchaseRecord: "Record",
        number: "Number",
        betCount: "Bet Count",
        status: "Status",
        inProgress: "In Progress",
        notWon: "Not Won",
        won: "Won",
        confirmPurchase: "Please Confirm Purchase",
        currentNumber: "Current Number",
        cancel: "Cancel",
        confirm: "Confirm",
        insufficientBalance: "Insufficient Balance",
        betCountAbnormal: "Bet Count Abnormal",
        purchaseSuccessful: "Purchase Successful"
    },
    ChargePage: {
        daaa: 'Activate',
        dasa: 'Charging',
        daaf: 'Charge Complete',
        dafda: 'Click to Charge',
        davxa: 'Can be Obtained',
        da33a: 'Collection Successful',
        da33ss:'Planet Level',
        da33aa:'Planet  Power',
        da33dddd:'Transport Difficulty',
        da33deefgg:'View More',
        da33sasd22g:'Output',
        errta:'Charging Record',
        errtas:'Time',
    },
    CommunityPage: {
        aaaa: 'My Community',
        bbbb: 'Community Projects',
        cccc: 'Project Name',
        dsa: 'Minimum Investment',
        dsww: 'Expected Total Investment',
        ass: 'Investment Proportion',
        wea: 'Shares',
        deeee: 'Private Placement Time',
        ssdw: 'Development Cycle',
        tyyyy: 'Months',
        tyydsfy: 'Beta Testing Time',
        tyffryyy: 'Launch Time',
        tyrssy: 'Investor Number Limit',
        tyygrrryy: 'People',
        tyrrryy: 'Return on Investment',
        tyyyyyy: 'Contact Information',
        tyiiiyy: 'Project Type',
        iityy: 'Choose Team',
        itiiiyyy: 'Members',
        tykkyy: 'Upload Image Introduction',
        tyyyyk: 'Upload File Package',
        tyyyyks: 'Project Introduction Information',
        tyyyyka: 'Submit Project',
        tyyyykf: 'Project Review Fee',
        tyyyysssskf:'Select Date and Time',
    },
    UserCommunity:{
        aaaaa: 'Community not found',
        bbbbb: 'Members',
        ccccc: 'Enter',
        ddddd: 'Join',
        eeeee: 'Please confirm creation',
        fffff: 'Cancel',
        ggggg: 'Confirm',
        hhhhh: 'Please confirm deletion',
        iiiii: 'Group name',
        jjjjj: 'Number of people',
        kkkkk: 'Group avatar',
        lllll: 'Group introduction',
        mmmmm: 'Create',
        nnnnn: 'Creation fee',
        ooooo: 'Whats new',
        ppppp: 'Post',
        qqqqq: 'Enter the community information you want to search',
        rrrrr: 'Create community',
        sssss: 'Community',
        ttttt: 'My community',
        uuuuu: 'My posts',
        vvvvv: 'Release time',
        wwwww: '',
        xxxxx: 'View details',
        yyyyy: 'Already at the bottom',
        zzzzz: 'Delete',
        aaaaaa: 'Click to load more',
        bbbbbb: 'Delete successful',
        cccccc: 'Delete failed',
        dddddd: 'Please complete personal information first',
        eeeeee: 'Please enter',
        ffffff: 'Abnormal community name length',
        gggggg: 'Abnormal community introduction length',
        hhhhhh: 'Please upload avatar',
        iiiiii: 'Insufficient balance',
        jjjjjj: 'Submitting',
        kkkkkk: 'Image upload error',
        llllll: 'Community created successfully',
        mmmmmm: 'Can only upload up to 9 images',
        nnnnnn: 'Please enter text',
        oooooo: 'Reply succeeded',
        pppppp: 'Reply failed',
        ssswww: 'Back',
        ssswwwa: 'Reply',
    },
    CommunityDetails:{
        asssss1:'Post',
        asssss2:'Please Confirm',
        asssss3:'Disband',
        asssss4:'Leave',
        asssss5:'Welcome to',
        asssss6:'Submitting',
        asssss7:'Please enter text',
        asssss8:'Image upload error',
        asssss9:'Post successful',
        asssss10:'Post failed',
        asssss11:'Reply successful',
        asssss12:'Reply failed',
        }
}

export default messages
